import { UserActions } from '../Actions';

const initialState = {
  user: null,
  user_error: "",
  user_not_approved: "",
  player: "",
  game: "",
  openPages: ["/not-found","/","/admin/console","/about","/privacypolicy"],
  login: "/",
  home: "/home",
  openPopup: '',
  showProgressBar: false,
  pageState: 100,
  errorCode: {
    response: '',
    request: ''
  },
  messages: [],
  localProgress: [],
  register: {
  },
  contact: {},
  verifyEmail: "",
  edit: null,
  editAddress: null,
  editSpouse: null,
  editKid: null,
  QRCODE: null,
  signup: {
    email: "",
    password: "",
    confirmpassword: "",
    error: null,
    completed: false
  },
  adminData:{
    approvedMembers:[],
    pendingMembers:[],
    pendingMembership:[]
  }
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActions.SIGNUP_UPD: {
      if (action.payload.error){
        return {
          ...state,
          signup: {
            ...state.signup,
            ...action.payload
          }
        };
      } else {
        return {
          ...state,
          signup: {
            ...state.signup,
            ...action.payload,
            error: null
          }
        };
      }
    }
    case UserActions.USER_UPDATE: {
      return {
        ...state,
        user: action.payload
      };
    }
    case UserActions.USER_EDIT: {
      if (action.payload === null){
        return {
          ...state,
          edit: null
        }
      }
      return {
        ...state,
        edit: {
          ...state.edit,
          ...action.payload
        }
      };
    }
    case UserActions.EDIT_ADDRESS: {
      if (action.payload === null){
        return {
          ...state,
          editAddress: null
        }
      }
      return {
        ...state,
        editAddress: {
          ...state.editAddress,
          ...action.payload
        }
      };
    }
    case UserActions.EDIT_SPOUSE: {
      if (action.payload === null){
        return {
          ...state,
          editSpouse: null
        }
      }
      return {
        ...state,
        editSpouse: {
          ...state.editSpouse,
          ...action.payload
        }
      };
    }
    case UserActions.EDIT_KID: {
      if (action.payload === null){
        return {
          ...state,
          editKid: null
        }
      }
      return {
        ...state,
        editKid: {
          ...state.editKid,
          ...action.payload
        }
      };
    }
    case UserActions.USER_NOT_APPROVED: {
      return {
        ...state,
        user_not_approved: action.payload
      };
    }
    case UserActions.USER_UPDATE_ERROR: {
      return {
        ...state,
        user_error: action.payload
      };
    }
    case UserActions.USER_RESET_ERROR: {
      return {
        ...state,
        user_error: "",
        user_not_approved: ""
      };
    }
    case UserActions.UPDATE: {
      return {
        ...state,
        register: {
          ...state.register,
          ...action.payload
        }
      };
    }
    case UserActions.UPDATE_LOGIN: {
      return {
        ...state,
        login: {
          ...state.login,
          ...action.payload
        }
      };
    }
    case UserActions.CONTACT: {
      return {
        ...state,
        contact: {
          ...state.contact,
          [action.payload.name]:action.payload.value
        }
      };
    }
    case UserActions.CONTACT_CLR: {
      return {
        ...state,
        contact: {
          submit: "success"
        }
      };
    }
    case UserActions.VERIFY: {
      return {
        ...state,
        verifyEmail: action.payload
      };
    }
    case UserActions.REGISTER_SUCESS: {
      return {
        ...state,
        register: {
          submit: "success"
        }
      };
    }
    case UserActions.REGISTER_VALID_FAIL: {
      return {
        ...state,
        register: {
          ...state.register,
          reason: action.payload
        }
      };
    }
    case UserActions.REGISTER_FAIL: {
      return {
        ...state,
        register: {
          submit: "fail",
          reason: action.payload
        }
      };
    }
    case UserActions.LOAD_ADMIN_PAGE: {
      return {
        ...state,
        adminData: action.payload
      };
    }
    case UserActions.QRCODE: {
      return {
        ...state,
        QRCODE: action.payload
      };
    }
    default:
      return state;
  }
};
