// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_selection{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}
.intro_main{ 
  margin: 0 auto;
}
.intro_content{
  text-align: justify;
}
@media (max-width:576px){
  .home_selection{flex-direction:column}
  .intro_main{ max-width: 90%; }
}
@media (max-width:768px) and (min-width:576px){
  .home_selection{flex-direction:column}
  .intro_main{ max-width: 536px; }
}
@media (max-width:992px) and (min-width:768px){
  .home_selection{flex-direction:column}
  .intro_main{ max-width: 728px; }
}
@media (max-width:1200px) and (min-width:992px){
  .home_selection{flex-direction:column}
  .intro_main{ max-width: 952px; }
}
@media (min-width:1200px){
  .home_selection{flex-direction:column}
  .intro_main{ max-width: 1200px; }
}`, "",{"version":3,"sources":["webpack://./src/Pages/HomePage/HomePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB,qBAAqB;EACrC,aAAa,cAAc,EAAE;AAC/B;AACA;EACE,gBAAgB,qBAAqB;EACrC,aAAa,gBAAgB,EAAE;AACjC;AACA;EACE,gBAAgB,qBAAqB;EACrC,aAAa,gBAAgB,EAAE;AACjC;AACA;EACE,gBAAgB,qBAAqB;EACrC,aAAa,gBAAgB,EAAE;AACjC;AACA;EACE,gBAAgB,qBAAqB;EACrC,aAAa,iBAAiB,EAAE;AAClC","sourcesContent":[".home_selection{\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 100%;\r\n  margin: auto;\r\n}\r\n.intro_main{ \r\n  margin: 0 auto;\r\n}\r\n.intro_content{\r\n  text-align: justify;\r\n}\r\n@media (max-width:576px){\r\n  .home_selection{flex-direction:column}\r\n  .intro_main{ max-width: 90%; }\r\n}\r\n@media (max-width:768px) and (min-width:576px){\r\n  .home_selection{flex-direction:column}\r\n  .intro_main{ max-width: 536px; }\r\n}\r\n@media (max-width:992px) and (min-width:768px){\r\n  .home_selection{flex-direction:column}\r\n  .intro_main{ max-width: 728px; }\r\n}\r\n@media (max-width:1200px) and (min-width:992px){\r\n  .home_selection{flex-direction:column}\r\n  .intro_main{ max-width: 952px; }\r\n}\r\n@media (min-width:1200px){\r\n  .home_selection{flex-direction:column}\r\n  .intro_main{ max-width: 1200px; }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
