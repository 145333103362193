import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './EventsPage.css';
import { OpenPopup } from '../../Actions';
import i18next from 'i18next';
import classNames from 'classnames';
import tn2022 from "../../Images/events/TamilNewYear2022.jpeg";
import cricket2022 from "../../Images/events/CricketTornamanet.jpeg";
import cricket2023 from "../../Images/events/CricketTornamanet2023.jpg";
import summergames2022 from "../../Images/events/SummarGames.png";
import diwali2022 from "../../Images/events/Diwali2022.png";
import seminar from "../../Images/events/FinanceSeminar.png";
import ecm2023first from "../../Images/events/ExecutiveCommitteeMeeting2023-1.png";
import pongal2023 from "../../Images/events/Pongal2023.png";
import newyear2023 from "../../Images/events/Tamil NewYear Poster-tamil2023.png";
import diwali2023 from "../../Images/events/Diwali2023.png";
import agm2024 from "../../Images/events/AGM24.png";
import vv2024 from "../../Images/events/VasanthaVizha2024.png";

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
    };
};

class _EventsPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) { 
        super(props);
        this.state = {
            selected: 0,
        }
    }
    render() {
        const years = ["2024","2023", "2022"];
        const events = {
            en: {
                "2022":[
                    { name: "Tamil New Year Celebration", pic: tn2022, title: "April 23" },
                    { name: "Älmhult Indian Cricket Tournament", pic: cricket2022, title: "August 13-14" },
                    { name: "Summer Games Festival", pic: summergames2022, title: "September 3" },
                    { name: "Diwali celebration", pic: diwali2022, title: "October 22" },
                ],
                "2023":[
                    { name: "Triple grand festival", pic: pongal2023, title: "January 14" },
                    { name: "Executive Committee Meeting", pic: ecm2023first, title: "January 28" },
                    { name: "Finance Seminar", pic: seminar, title: "February 18" },
                    { name: "Tamil New Year Celebration", pic: newyear2023, title: "May 7" },
                    { name: "SweDen Thamizhar Cup", pic: cricket2023, title: "September 16" },
                    { name: "Diwali celebration", pic: diwali2023, title: "November 11" },
                ],
                "2024":[
                    { name: "Annual General Meeting", pic: agm2024, title: "March 27" },
                    { name: "Vasantha Vizha", pic: vv2024, title: "May 18"}
                ]
            },
            ta:{
                "2022":[
                    { name: "தமிழ் புத்தாண்டு கொண்டாட்டம்", pic: tn2022, title: "ஏப்ரல் 23" },
                    { name: "எல்முல்ட் இந்தியன் கிரிக்கெட் போட்டி", pic: cricket2022, title: "ஆகஸ்ட் 13-14" },
                    { name: "கோடை விளையாட்டு விழா", pic: summergames2022, title: "செப்டம்பர் 3" },
                    { name: "தீபாவளி கொண்டாட்டம்", pic: diwali2022, title: "அக்டோபர் 22" },
                ],
                "2023":[
                    { name: "முப்பெரும் விழா", pic: pongal2023, title: "ஜனவரி 14" },
                    { name: "செயற்குழு கூட்டம்", pic: ecm2023first, title: "ஜனவரி 28" },
                    { name: "நிதி கருத்தரங்கு", pic: seminar, title: "பிப்ரவரி 18" },
                    { name: "தமிழ் புத்தாண்டு கொண்டாட்டம்", pic: newyear2023, title: "மே 7" },
                    { name: "ஸ்வீடென் தமிழர் கோப்பை", pic: cricket2023, title: "செப்டம்பர் 16" },
                    { name: "தீபாவளி கொண்டாட்டம்", pic: diwali2023, title: "நவம்பர் 11" },
                ],
                "2024":[
                    { name: "ஆண்டு பொது கூட்டம்", pic: agm2024, title: "மார்ச் 27" },
                    { name: "வசந்த விழா", pic: vv2024, title: "மே 18"}
                ]
            }
        };
        return (
            <div className="contact_container">
                <div className='contact_page_header'>
                    <h3>{i18next.t('events.pagetitle')}</h3>
                </div>
                <div className='events'>
                    <div className='events_tab_container'>
                        {
                            years.map((x,i)=>{
                                return(
                                    <div key={i} className={classNames('events_year_tab', this.state.selected === i && 'events_tab_selected')} onClick={()=>{this.setState({ selected: i})}}>
                                        {x}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='event_container'>
                        {
                            events[this.props.app.language === 'ta' ? 'ta' : 'en' ][years[this.state.selected]].length > 0 ? events[this.props.app.language === 'ta' ? 'ta' : 'en' ][years[this.state.selected]].map((x,i)=>{
                                return(
                                    <div key={i} className={classNames('event_content')}>
                                        <div className={classNames('event_pic')}>
                                            <img alt='BAPA' src={x.pic} className="event_image"></img>
                                        </div>
                                        <div className={classNames('event_name')}>
                                            {x.name}
                                        </div>
                                        <div className={classNames('event_date')}>
                                            {x.title}
                                        </div>
                                    </div>
                                )
                            }):
                            <div className='no_events_available'>
                                No event details found.
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export const EventsPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_EventsPage)
);