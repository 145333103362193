function prefix(action) {
  return `APP_${action}`;
}
  
export const AppActions = {
  DEV_MODE: prefix('DEV_MODE'),
  ERROR: prefix('ERROR'),
  LANG: prefix('LANG'),
  POPUP: prefix('POPUP'),
  RESET_ERR: prefix('RESET_ERR'),
  SHOW_PB: prefix('SHOW_PB'),
  HIDE_PB: prefix('HIDE_PB'),
  REG_FAIL: prefix('REG_FAIL'),
  INAGURATE: prefix('INAGURATE'),
  INAGURATE_USER: prefix('INAGURATE_USER'),
};

export const DeveloperMode = mode => {
  return {
    type: AppActions.DEV_MODE,
    payload: mode
  };
};

export const ClearErrorMessages = () => {
  return {
    type: AppActions.ERROR,
    payload: null
  };
};

export const OpenPopup = name => {
  return {
    type: AppActions.POPUP,
    payload: name
  };
};

export const ChangeLanguage = lang => dispatch => {
    dispatch({ type: AppActions.LANG, payload: lang });
};

// export const GetSiteInagurationStatus = () => dispatch => {
//   const db = getDatabase();
//   const starCountRef = ref(db, 'config');
//   onValue(starCountRef, (snapshot) => {
//     const data = snapshot.val();
//     // console.log('config', data);
//     dispatch({ type: AppActions.INAGURATE, payload : data });
//     if (!data["website-started"]){
//       const users = ref(db, 'users');
//       onValue(users, (snapshot) => {
//         const data = snapshot.val();
//         var list = [];
//         data && Object.keys(data).forEach((x,i)=>{
//           list.push({username: data[x].username, id: data[x].id});
//         });
//         list = list.sort(function(a, b) {
//           return a.id - b.id;
//         });
//         // console.log('users', data);
//         dispatch({ type: AppActions.INAGURATE_USER, payload : list });
//       });
//     }
//   });
// }

// export const AddNameToFirebase = (name) => dispatch => {
//   const db = getDatabase();
//   set(ref(db, 'users/u-' + (Math.floor(Math.random()*1000000000000000)) + name), {
//     username: name
//   });
// }