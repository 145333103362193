import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './BoardMembersPage.css';
import { OpenPopup } from '../../Actions';
import i18next from 'i18next';
import classNames from 'classnames';
import { Icon } from '../../Components';
import neethirajan from '../../Images/boardmembers/Neethirajan.png';
import sriram from '../../Images/boardmembers/Sriram.png';
import sanjana from '../../Images/boardmembers/Sanjana.png';
import ramanathan from '../../Images/boardmembers/Ramanathan.png';
import hariharan from '../../Images/boardmembers/Hariharan.png';
import harish from '../../Images/boardmembers/Harish.png';
import prakash from '../../Images/boardmembers/Prakash.png';
import ramprabhu from '../../Images/boardmembers/RamPrabhu.png';
import vidhya from '../../Images/boardmembers/Vidhya.png';
import karthikeyan from '../../Images/boardmembers/karthikeyan.png';
import saravanan from '../../Images/boardmembers/saravanan.png';
import karthickbalaji from '../../Images/boardmembers/karthickbalaji.png';
import shalini from '../../Images/boardmembers/Shalini.png';
import moment from 'moment';


function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        openPopup: data => {
          dispatch(OpenPopup(data));
        },
    };
};

class _BoardMembersPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object
    };
    constructor(props) { 
        super(props);
        this.state = {
            selected: 0,
        }
    }
    render() {
        const years = ["FY 2023","FY 2022","FY 2021"];
        if (moment() > moment(new Date('Wed Mar 27 2024 20:30:00 GMT+0200 (CEST)'))){
            years.unshift("FY 2024");
        }
        const members = {
            en: {
                "FY 2024":[
                    { name: "Harish Vaithianathan", pic: harish, title: "President" },
                    { name: "Sriram Srinivasan", pic: sriram, title: "Secretary" },
                    { name: "Ramanathan Singaram", pic: ramanathan, title: "Treasurer" },
                    { name: "Karthikeyan Kandasamy", pic: karthikeyan, title: "Joint Secretary" },
                    { name: "Shalini Kannaiyan", pic: shalini, title: "Cultural Secretary" },
                    { name: "Saravanan Loganathan", pic: saravanan, title: "Sports Secretary" },
                    { name: "Karthick Balaji", pic: karthickbalaji, title: "Relationship and Communication Secretary" },
                ],
                "FY 2023":[
                    { name: "Neethirajan Balachandran", pic: neethirajan, title: "President" },
                    { name: "Sriram Srinivasan", pic: sriram, title: "Secretary" },
                    { name: "Ramanathan Singaram", pic: ramanathan, title: "Treasurer" },
                    { name: "Harish Vaithianathan", pic: harish, title: "Joint Secretary" },
                    { name: "Sanjana Shankar", pic: sanjana, title: "Cultural Secretary" },
                    { name: "Vidhyalakshmi Sampathkumar", pic: vidhya, title: "Sports Secretary" },
                    { name: "Hariharan Sekar", pic: hariharan, title: "Relationship and Communication Secretary" }
                ],
                "FY 2022":[
                    { name: "Neethirajan Balachandran", pic: neethirajan, title: "President" },
                    { name: "Ramprabu Navaneethakannan", pic: ramprabhu, title: "Secretary" },
                    { name: "Prakash Ramaraj", pic: prakash, title: "Treasurer" },
                    { name: "Sanjana Shankar", pic: sanjana, title: "Cultural and Sports Secretary" },
                    { name: "Hariharan Sekar", pic: hariharan, title: "Relationship and Communication Secretary" }
                ],
                "FY 2021":[
                    { name: "Elamaran Police", pic: "", title: "President" },
                    { name: "Indumathi Vasudevan", pic: "", title: "Secretary" },
                    { name: "Bhuvaneshwari Kumar", pic: "", title: "Treasurer" },
                    { name: "Sandhya Valsappan", pic: "", title: "Joint Secretary" },
                    { name: "Ramprabu Navaneethakannan", pic: "", title: "Cultural Secretary" },
                    { name: "SathishKumar Ramasamy", pic: "", title: "Sports Secretary" },
                    { name: "Lavanya RajeshBabu", pic: "", title: "Relationship and Communication Secretary" }
                ]
            },
            ta:{
                "FY 2024":[
                    { name: "ஹரிஷ் வைத்தியநாதன்", pic: harish, title: "தலைவர்" },
                    { name: "ஸ்ரீராம் சீனிவாசன்", pic: sriram, title: "செயலாளர்" },
                    { name: "ராமநாதன் சிங்காரம்", pic: ramanathan, title: "பொருளாளர்" },
                    { name: "கார்த்திகேயன் கந்தசாமி", pic: karthikeyan, title: "இணைச் செயலாளர்" },
                    { name: "ஷாலினி கண்ணையன்", pic: shalini, title: "கலாச்சார செயலாளர்" },
                    { name: "சரவணன் லோகநாதன்", pic: saravanan, title: "விளையாட்டு செயலாளர்" },
                    { name: "கார்த்திக் பாலாஜி", pic: karthickbalaji, title: "தகவல் மற்றும் தொடர்பு செயலாளர்" },
                ],
                "FY 2023":[
                    { name: "நீதிராஜன் பாலச்சந்திரன்", pic: neethirajan, title: "தலைவர்" },
                    { name: "ஸ்ரீராம் சீனிவாசன்", pic: sriram, title: "செயலாளர்" },
                    { name: "ராமநாதன் சிங்காரம்", pic: ramanathan, title: "பொருளாளர்" },
                    { name: "ஹரிஷ் வைத்தியநாதன்", pic: harish, title: "இணைச் செயலாளர்" },
                    { name: "சஞ்சனா சங்கர்", pic: sanjana, title: "கலாச்சார செயலாளர்" },
                    { name: "வித்யாலட்சுமி சம்பத்குமார்", pic: vidhya, title: "விளையாட்டு செயலாளர்" },
                    { name: "ஹரிஹரன் சேகர்", pic: hariharan, title: "தகவல் மற்றும் தொடர்பு செயலாளர்" }
                ],
                "FY 2022":[
                    { name: "நீதிராஜன் பாலச்சந்திரன்", pic: neethirajan, title: "தலைவர்" },
                    { name: "ராம்பிரபு நவநீதகண்ணன்", pic: ramprabhu, title: "செயலாளர்" },
                    { name: "பிரகாஷ் ராமராஜ்", pic: prakash, title: "பொருளாளர்" },
                    { name: "சஞ்சனா சங்கர்", pic: sanjana, title: "கலாச்சார மற்றும் விளையாட்டு செயலாளர்" },
                    { name: "ஹரிஹரன் சேகர்", pic: hariharan, title: "தகவல் மற்றும் தொடர்பு செயலாளர்" }
                ],
                "FY 2021":[
                    { name: "இளமாறன் போலீஸ்", pic: "", title: "தலைவர்" },
                    { name: "இந்துமதி வாசுதேவன்", pic: "", title: "செயலாளர்" },
                    { name: "புவனேஸ்வரி குமார்", pic: "", title: "பொருளாளர்" },
                    { name: "சந்தியா வலசப்பன்", pic: "", title: "இணைச் செயலாளர்" },
                    { name: "ராம்பிரபு நவநீதகண்ணன்", pic: "", title: "கலாச்சார செயலாளர்" },
                    { name: "சதீஷ்குமார் ராமசாமி", pic: "", title: "விளையாட்டு செயலாளர்" },
                    { name: "லாவண்யா ராஜேஷ்பாபு", pic: "", title: "தகவல் மற்றும் தொடர்பு செயலாளர்" }
                ]
            }
        };
        return (
            <div className="register_container">
                <div className='contact_page_header'>
                    <h3>{i18next.t('boardmembers.pagetitle')}</h3>
                </div>
                <div className='board_members'>
                    <div className='board_tab_container'>
                        {
                            years.map((x,i)=>{
                                return(
                                    <div key={i} className={classNames('board_year_tab', this.state.selected === i && 'board_tab_selected')} onClick={()=>{this.setState({ selected: i})}}>
                                        {x}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='board_member_container'>
                        {
                            members[this.props.app.language === 'ta' ? 'ta' : 'en' ][years[this.state.selected]].map((x,i)=>{
                                return(
                                    <div key={i} className={classNames('board_member_content')}>
                                        <div className={classNames('board_member_pic')}>
                                            {
                                                x.pic === "" ? 
                                                <Icon>profilePic</Icon>
                                                :
                                                <img alt='BAPA' src={x.pic} className="members_pic"></img>
                                            }
                                        </div>
                                        <div className={classNames('board_member_name')}>
                                            {x.name}
                                        </div>
                                        {
                                            x.email && 
                                            <div className={classNames('board_member_title')}>
                                                {x.email}
                                            </div>
                                        }
                                        <div className={classNames('board_member_title')}>
                                            {x.title}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export const BoardMembersPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_BoardMembersPage)
);