import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { userReducer } from './userReducer';
// import { electionReducer } from './electionReducer';
import { fieldsValidationsReducer } from './fieldsValidationsReducer';

export default combineReducers({
  app: appReducer,
  user: userReducer,
  // election: electionReducer,
  field: fieldsValidationsReducer
});
