// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_view{
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;
    min-width: 680px;
}
.table_view_head_row{
    line-height: 1.5rem;
    font-family: "Public Sans", sans-serif;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px dashed rgb(241, 243, 244);
    text-align: left;
    padding: 16px;
    font-size: 14px;
    color: rgb(99, 115, 129);
    font-weight: 600;
    background-color: rgb(244, 246, 248);
}
.table_view_column{
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px dashed rgb(241, 243, 244);
    text-align: left;
    padding: 16px;
    color: rgb(33, 43, 54);
}`, "",{"version":3,"sources":["webpack://./src/Components/BasicComponents/TableView.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,sCAAsC;IACtC,mBAAmB;IACnB,uBAAuB;IACvB,4CAA4C;IAC5C,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,wBAAwB;IACxB,gBAAgB;IAChB,oCAAoC;AACxC;AACA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,sCAAsC;IACtC,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,4CAA4C;IAC5C,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".table_view{\r\n    display: table;\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    border-spacing: 0px;\r\n    min-width: 680px;\r\n}\r\n.table_view_head_row{\r\n    line-height: 1.5rem;\r\n    font-family: \"Public Sans\", sans-serif;\r\n    display: table-cell;\r\n    vertical-align: inherit;\r\n    border-bottom: 1px dashed rgb(241, 243, 244);\r\n    text-align: left;\r\n    padding: 16px;\r\n    font-size: 14px;\r\n    color: rgb(99, 115, 129);\r\n    font-weight: 600;\r\n    background-color: rgb(244, 246, 248);\r\n}\r\n.table_view_column{\r\n    line-height: 1.57143;\r\n    font-size: 0.875rem;\r\n    font-family: \"Public Sans\", sans-serif;\r\n    font-weight: 400;\r\n    display: table-cell;\r\n    vertical-align: inherit;\r\n    border-bottom: 1px dashed rgb(241, 243, 244);\r\n    text-align: left;\r\n    padding: 16px;\r\n    color: rgb(33, 43, 54);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
